/* Common Footer Styles */
/* ------------------- */
/* Styles defined here are specific to elements found on the "common footer" web component */
/* This file is imported by "global-styles.scss" */

//footer
#common-footer-element {
  max-width: 800px;
  margin: auto;
  padding: 15px;
}

#footer {
  width: 100%;
  margin: auto;
  margin-top:36px;
  border-top: 1px solid #000;

  .img-fluid {
    width: 100%;
    max-width: 100%;
    height: 50px;
    margin-top: 15px;
  
    &.corporate-logo {
      width: 62px !important;
      height: 20px !important;
    }
  }

  @media screen and (max-width: 768px) {
    .img-fluid {
      margin-bottom: 15px;
    }
  }
}

#view-cookie-settings:hover {
  cursor: pointer;
  text-decoration: underline;
}

.legal-link {
  display: block;
  color: $black !important;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
    color: $black !important;
    font-weight: bold;
  }

  &:visited {
    color: $black !important;
  }
}

.request-link {
  margin-left: -12px
}

.legal-notices, #org-switcher {
  .col-md-6 {
    padding: 0;
    margin-top: 0;
  }

  a {
    margin-bottom: .5em;
  }
}


