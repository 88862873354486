/* styling for modals */

.modal-auto-height {
  .modal-content {
    max-height: calc(100vh - 1em);

    .modal-body {
      max-height: calc(100vh - 5em);
      overflow-x: auto;

      .embed-responsive {
        height: calc(100vh - 8em);
      }
    }
  }

  .notice-modal-dialog {
    margin-top: unset;
  }
}