/* styling for legal pages */

//legal docs
//Terms of Use & Privacy Notice
#TOU,
#PN {
  ul li {
    list-style: none;
    display: flex;
    align-items: flex-start;
  }

  .number {
    width: 2.5em;
  }

  .number.sub {
    width: 3em;
    margin-left: 2.5em;
  }

  .content {
    flex: 1;
  }
}

#CN {
  h2 {
    font-size: 1rem;
    line-height: 1rem;
    font-weight: bold;
  }
}

.otnotice-content *,
.otnotice-content *:before,
.otnotice-content *:after {
  box-sizing: inherit;
  font-size: 1em !important;
  line-height: 1.5em !important;
  font-family: Arial, Arial, Helvetica, sans-serif !important;
}

.otnotice-content .otnotice-sections .otnotice-section-content p {
  margin: 0 0 1rem !important;
}

h2.otnotice-section-header {
  font-size: 1.5em !important;
}

.otnotice-content h1 {
  font-size: 1.75em !important;
}

.otnotice-content a,
.otnotice-content a:visited {
  color: $software-blue !important;
  font-weight: bold;
  text-decoration: underline;
  
  &:hover {
    color: darken($software-blue, 5%) !important;
  }
}