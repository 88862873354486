﻿/* Master color definitions */
@import "base/colors";

/* Import Bootstrap elements used in our global stylesheet */
@import "_bootstrap-core-scss/functions";
@import "_bootstrap-core-scss/variables";
@import "_bootstrap-core-scss/mixins";

/* Import base styles referenced/extended in global and partials */
@import "base/mixins";

/* Azure B2C  Styles */
/* ------------------- */
/* Styles defined here are specific to elements found on the Azure AD B2C pages (signin, signup, corpEmail signup  pages */

#badge-title {
  max-height: 60px;
  color: $violet;
  font-weight: bolder;
  margin-top: 7px;
  margin-left: 10px;
}

.badge-dialog {
  margin-top: 5rem;
}

#badge-link {
  max-height: 60px;
  font-weight: normal;
  margin-top: -6px;
}

#badge-close-button {
  border: none;
  float: right;
}

#badge-modal-title {
  color: $violet;
  margin-top: 20px;
  margin-left: 15px;
  font-weight: bold;
  vertical-align: middle;
}

.cls-1 {
  fill: $violet;
}

.cls-2 {
  fill: $white;
  stroke: $violet;
  stroke-miterlimit: 10;
  stroke-width: 48px;
}