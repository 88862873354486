/* styling for legacy-browser-notice in b2c signin page */
#legacy-browser-notice-modal {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1060;

    .notice-modal-dialog {
        width: 30rem;
    }

    .notice-modal-header {
        border-bottom: none;
        margin-top: 10px;

        .close-modal {
            position: absolute;
            top: 0;
            right: 0px;
        }
    }

    .modal-body {
        ul.browsers {
            display: flex;
            padding-inline-start: 0;
            
            justify-content: space-around; // space-evenly is not working in IE, make it space-around and add padding
            padding: 0px 15px;

            flex-direction: row;
            align-content: center;
            align-items: center;

            >li {
                list-style-type: none;
                width: 80px;

                img {
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }

    .modal-footer {
        text-align: center;
        justify-content: center;

        .footer-intro {
            /* font-weight: bold; */
        }
    }
}