/* Common Footer Styles */
/* ------------------- */
/* Styles defined here are responsive overrides for specific to elements found on the "common footer" web component */
/* This file is imported by "_common-footer.scss" */

@include media-breakpoint-up(md) {

  #footer-content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;


    .legal-notices, #org-switcher {
      width: 50%;
      margin-left: auto;
      display: flex;
      margin-top: 14px;

      .col-md-6 {
        padding-left: 15px;
      }
    }
  }

  #copyright {
    margin-top: -2em;
  }
}