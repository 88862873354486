/* Registration Styles */
/* ------------------- */
/* Styles defined here are responsive overrides for specific to elements found on the "lookup code" and "self-identification" screens */
/* This file is imported by "_registration.scss" */



@media only screen and (min-width: 850px) {

  /*
  Self Identification, Hire Date, etc
  */
  .intl-tel-input input,
  .intl-tel-input input[type=text],
  .intl-tel-input input[type=tel] {
    width: 240%;
  }

  /*
  Lookup code and instructions
  */
}


