﻿/*#region MESSAGE MIXINS*/
@mixin validation-message($msgColor) {
    color: $msgColor;
    width: 100%;
    font-size: .875em !important;
}

@mixin card-header() {
    font-size: 1.5em;
}

/*#endregion BASE COMMON STYLES*/