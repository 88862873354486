/* Buttons and Links  */
a,
a:visited,
.btn-link {
  color: $software-blue;

  &:hover {
    color: $software-blue;
    text-decoration: underline;
  }
}

.btn-primary,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:not(:disabled),
:not(:disabled).btn-primary {
  color: #fff;
  background: $software-blue !important;
  border-color: $software-blue;
  border-radius: 0;
  margin-left: 10px;

  &:hover {
    background-color: darken($software-blue, 5%) !important;
  }
}

.btn-secondary,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary:not(:disabled),
:not(:disabled).btn-secondary {
  color: $software-blue !important;
  background: $white !important;
  border-color: $software-blue;
  border-radius: 0;

  &:hover {
    color: darken($software-blue, 5%) !important;
    border-color: darken($software-blue, 5%);
    background: $white !important;
  }
}

.btn:disabled {
  background-color: $software-gray-7;
  border-color: $software-gray-7;
  color: $white;

  &:hover {
    cursor: not-allowed;
  }
}

button {
  @extend .btn;
}

.btn-primary {
  color: $white;
  background-color: $software-blue;
  border-radius: 0;
  margin-left: 0.5em;
}

.btn-outline-secondary {
  border-color: $software-blue;
  color: $software-blue;
  border-radius: 0;
}