/* Common Header Styles */
/* ------------------- */
/* Styles defined here are specific to elements found on the "common header" web component */
/* This file is imported by "global-styles.scss" */

header {
  width: 100%;
}

#common-header-element {
  padding: 0 0;
  max-width: 800px;
  margin: auto;
}

/* Branding */
#header-container {
  #header {
    width: 100%;
    margin: 0 0 0.5em 0;
    padding: 15px 15px 0 15px;
    display: none;

    li {
      margin: auto;
      padding: 0.5em 0 0.5em 0;
      list-style: none;
      /* hide wtw logo by default, and display for larger screen sizes */
      
      &.wtw {
        width: 50%;
        max-width: 300px;
        display: none;

        .wtw-logo {
          width: 284px;
        }

        .wtw-logo.sign-in {
          width: 100px !important;
          height: 32px !important;
        }
      }

      .brand-logo {
        max-width: 160px;
        max-height: 100px;
        margin-right: 1em;
      }
    }
  }

  &.no-brand-logo #header {
    display: block;

    li.wtw {
      display: inline;
    }
  }

  li.wtw {
    display: inline;
  }

  .navbar {
    margin-bottom: 10px;
    margin-top: 10px;

    .brand-logo {
      margin: auto;
      text-align: center;
      max-width: 160px;
      max-height: 50px;
      display: block;
    }
  }
}
