/* Registration Styles */
/* ------------------- */
/* Styles defined here are specific to elements found on the "lookup code" and "self-identification" screens */
/* This file is imported by "global-styles.scss" */

/*
Lookup code and instructions
*/
app-lookup-code-form {
  /* Hides the arrows on a number input for Chrome, Safari, Edge, Opera */
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Hides the arrows on a number input for Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

#lookup-instructions-container {
  h1.card-title {
    font-size: 1.75em;
    font-weight: bold;
  }

  #lookup-instructions {
    list-style: none;
    padding-left: 0;

    li.instruction-step {
      h2 {
        font-weight: bold;
      }

      p {
        font-size: 1em;
      }
    }
  }
}

.label-with-icon {
  display: flex;
  flex-direction: row;

  .info-icon {
    cursor: pointer;
    margin-left: 0.5em;

    svg {
      fill: $software-blue;
    }
  }
}

.error-msg {
  @include validation-message($software-danger);
}

.success-msg {
  @include validation-message($software-success);
}

/*
Self Identification, Hire Dat, etc
*/

app-self-identification {
    .input-group-append {
        z-index: auto;
    }
}

.input-group-text {
  height: 38.5px !important;
  margin-top: -1.5px;
}

.hire-date-picker .mat-calendar-period-button {
  pointer-events: none;
}

.hire-date-picker .mat-calendar-arrow {
  display: none;
}


/*.mat-datepicker-toggle {
  background: gray url("./2020-05-18_1-27-45.png") no-repeat !important;
}*/


.wtwcss {
  min-width: 900px !important;
  width: 1000px;
  height: 200px !important;
  padding-right: 2000px !important;
  color: aqua;
}

.iti {
  width: 100%;
}

.intl-tel-input input, .intl-tel-input input[type=text], .intl-tel-input input[type=tel] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
  width: 900px;
}

.error-shift-top {
  margin-top: -14px;
}

.space {
  height: 50px;
}

.btn-sio {
  margin-left: 10px;
}

.input-group-text {
  height: 38.5px !important;
  margin-top: -1.4px;
}

.hire-date-picker .mat-calendar-period-button {
  pointer-events: none;
}

.hire-date-picker .mat-calendar-arrow {
  display: none;
}

