﻿
@media only screen and (min-width: 601px) {

  input#emailTypeForRegistration_corporateEmail {
    margin-top: 0.3rem;
  }

  label[for=emailTypeForRegistration_corporateEmail] {
    padding-left: 10px;
  }

  input#emailTypeForRegistration_personalEmail {
    margin-top: 0.3rem;
  }

  .notice-modal-dialog {
    width:40rem;
    max-width: 45rem;
    margin-top: 10rem;
  }

}

@media only screen and (max-width: 600px) {

  label[for=emailTypeForRegistration_corporateEmail] {
    padding-left: 1.2rem;
  }

  input#emailTypeForRegistration_personalEmail {
    margin-top: .3rem;
  }

  .notice-modal-dialog {
    margin-top: 10rem;
  }
}



@media only screen and (max-width: 490px) {
  input#emailTypeForRegistration_corporateEmail {
    margin-top: .3rem;
    position: absolute;
  }
}

@media (min-width:490px) and (max-width:575px) {
    input#emailTypeForRegistration_personalEmail {
      margin-top: 2.3rem;
    }
  }


@media (min-width:575px) and (max-width:600px) {
  input#emailTypeForRegistration_corporateEmail {
    margin-top: 2.3rem;
  }
    input#emailTypeForRegistration_personalEmail {
      margin-top: 2.3rem;
    }
  }