/* Common Header Styles */
/* ------------------- */
/* Styles defined here are responsive overrides for specific to elements found on the "common header" web component */
/* This file is imported by "_common-header.scss" */

/* Larger screens */
@media (min-width: 768px) {
  #header-container {
    #header {
      display: block;

      li {
        list-style: none;
        display: inline;
        max-width: 160px;
        // un-hide wtw logo for larger screen sizes
        &.wtw {
          display: inline-block;
        }
      }
    }

    .navbar {
      display: none;
    }
  }
}
/* Small screens, for lookup screen - Embark/WTW logo only */
@media (max-width: 768px) {
  #header-container #header li.wtw .wtw-logo {
    margin: auto;
    display: block;
  }
}